import React from "react";
import { graphql, Link } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from "../lib/helpers";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SolutionKeyFeatures from "../components/solution-key-features/solution-key-features";
import Partners from "../components/partners/partners";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import loadable from "@loadable/component";
import HeroSection from "../components/hero-section/hero-section";
import BlockContent from "../components/block-content";
import Icon from "../components/icon";
import { imageUrlFor } from "../lib/image-url";

const StoryComponent = loadable(() => import("../components/story-telling/story"));
const TestiminialPreviewComponent = loadable(() =>
  import("../components/customer-testimonial-preview/customer-testimonial-preview")
);

const HubspotFormComponent = loadable(() => import("../components/hubspot-form/hubspot-form"));

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const customersData = data && data.customers.nodes;
  const customers = customersData[0].customers;
  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : [];

  return (
    <Layout role="main">
      <SEO title={site.title} description={site.seo} keywords={site.keywords} image={imageUrl} />
      <HeroSection>
        <div>
          {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
          <Link to="/get-started#get-started-form" className="primary-link-btn">
            Get started
          </Link>
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay="350"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <Icon symbol="axeptiaXxplainedV2"></Icon>
        </div>
      </HeroSection>
      <div style={{ minHeight: "100vh" }}>
        <StoryComponent site={site}></StoryComponent>
      </div>
      <Container role="complementary" backgroundColor="white">
        <SolutionKeyFeatures></SolutionKeyFeatures>
      </Container>
      <Container role="complementary">
        {customers && (
          <TestiminialPreviewComponent customers={customers}></TestiminialPreviewComponent>
        )}
      </Container>

      <Container backgroundColor="white" role="complementary">
        <Partners></Partners>
      </Container>
      <Container role="complementary">
        {projectNodes && (
          <ProjectPreviewGrid title="News" nodes={projectNodes} browseMoreHref="/archive/" />
        )}
      </Container>
      <Container backgroundColor="white" role="complementary" id="newsletter-form">
        <HubspotFormComponent
          title="Subscribe to our newsletter"
          portalId={5841642}
          formId="57391973-0e04-41aa-b72a-0602256c3305"
          region="na1"
        ></HubspotFormComponent>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings {
      title
      subtitle
      _rawDescription
      seo
      keywords
      actionButtonUrl
      actionButtonText
      mainImage {
        asset {
          _id
        }
        alt
      }
      solutionSummaryImage {
        asset {
          _id
        }
        alt
      }
    }
    customers: allSanityCustomers {
      nodes {
        title
        customers {
          name
          _rawDescription
          logo {
            alt
            asset {
              _id
            }
          }
        }
      }
    }
    projects: allSanityPost(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          isNorwegian
          _rawExcerpt
          publishedAt
          slug {
            current
          }
        }
      }
    }
  }
`;
